.custom-splitter2 > .layout-splitter {
  width: 10px;
  height: 140px;
  cursor: col-resize;

  margin: auto -10px auto auto;

  /* Primary */
  position: relative;
  top: 40px;
  right: 5px;

  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  z-index: 10;
  background: #f8f8f8
    linear-gradient(
      to right,
      #f8f8f8,
      #f8f8f8 27%,
      #56ccf2 27%,
      #56ccf2 39%,
      #f8f8f8 39%,
      #f8f8f8 60%,
      #56ccf2 60%,
      #56ccf2 72%,
      #f8f8f8 72%,
      #f8f8f8
    )
    no-repeat bottom;
  background-size: 100% 90%;
  background-position: center;
}

.custom-splitter2 > .layout-pane {
  display: flex;
}

/*Removes hover color changes... */
.splitter-layout .layout-splitter:hover {
  background-color: #f8f8f8;
}
.splitter-layout.layout-changing > .layout-splitter {
  background-color: #f8f8f8;
}
